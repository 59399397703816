import { EditSessionActivityProps } from "./types";
import { PrismaModels, trpc } from "@arena-active/trpc-client";
import { useEffect, useState } from "react";
import { LessonSelector } from "../LessonSelector";

export const EditLessonActivity: React.FC<EditSessionActivityProps> = ({
  activity,
  save,
}) => {
  const { data, isLoading } = trpc.lesson.list.useQuery({});

  type Lesson = PrismaModels["Lesson"];

  const [selectedLesson, setSelectedLesson] = useState<Lesson | undefined>();

  function onDropdownChange(value: Lesson) {
    setSelectedLesson(value);
    save({ ...activity, lessonId: value?.id ?? undefined });
  }

  useEffect(() => {
    if (!isLoading && activity.lessonId && data?.lessons) {
      setSelectedLesson(data.lessons.find((l) => l.id === activity.lessonId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity.lessonId, isLoading]);

  return (
    <div>
      {isLoading ? (
        <div>Loading lessons...</div>
      ) : (
        <div>
          <LessonSelector
            selectedLessonId={selectedLesson?.id}
            onSelectionChange={(lesson) => {
              lesson && onDropdownChange(lesson);
            }}
          />
        </div>
      )}
    </div>
  );
};
