import { FC, useRef } from "react";
import {
  Pause,
  Play,
  VolumeDown,
  Settings,
  VolumeMute,
  VolumeUp,
  Playback,
  SubTitles,
  Quality,
} from "../common/Icons";
import { MenuItem, Option } from "./SettingsMenu";
import styles from "./MediaPlayer.module.css";
import { Button } from "primereact/button";
import { formatTime } from "../../util";
import TrackBar from "./TrackBar";
import { classNames } from "primereact/utils";

export type SettingsType = {
  subtitle: Option;
  playbackSpeed: Option;
  quality: Option;
};

const playbackOptions: Array<Option> = [
  { id: "0.25", label: "0.25" },
  { id: "0.5", label: "0.5" },
  { id: "0.75", label: "0.75" },
  { id: "normal", label: "Normal" },
  { id: "1.25", label: "1.25" },
  { id: "1.5", label: "1.5" },
  { id: "1.75", label: "1.75" },
  { id: "2", label: "2" },
];

export const menuItems: Array<MenuItem<Option>> = [
  {
    name: "playbackSpeed",
    icon: <Playback />,
    label: "Playback speed",
    value: "normal",
    options: playbackOptions,
  },
  {
    name: "subtitles",
    icon: <SubTitles />,
    label: "Subtitle/CC",
    value: "Off",
    options: [],
  },
  {
    name: "quality",
    icon: <Quality />,
    label: "Quality",
    value: "Auto",
    options: [],
  },
];

const RenderVolumeIcon: FC<{
  volumeLevel: number;
  isAudio: boolean | undefined;
}> = ({ volumeLevel, isAudio }) => {
  if (volumeLevel === 0) {
    return <VolumeMute fillColor={isAudio ? "var(--text-body)" : undefined} />;
  }
  if (volumeLevel > 0 && volumeLevel < 50) {
    return <VolumeDown fillColor={isAudio ? "var(--text-body)" : undefined} />;
  }

  if (volumeLevel >= 50) {
    return <VolumeUp fillColor={isAudio ? "var(--text-body)" : undefined} />;
  }

  return <VolumeDown fillColor={isAudio ? "var(--text-body)" : undefined} />;
};

interface PlayControlProps {
  isPlaying: boolean;
  onPause: () => void;
  onPlay: () => void;
  isAudio?: boolean;
}

export function PlayControl({
  isPlaying,
  onPause,
  onPlay,
  isAudio,
}: PlayControlProps) {
  return (
    <Button
      className={`${styles.playButton} tooltip`}
      icon={
        isPlaying ? (
          <Pause fillColor={isAudio ? "var(--text-body)" : undefined} />
        ) : (
          <Play fillColor={isAudio ? "var(--text-body)" : undefined} />
        )
      }
      onClick={isPlaying ? onPause : onPlay}
      data-pr-tooltip={isPlaying ? "Pause (P)" : "Play (P)"}
      data-pr-position="top"
      data-pr-at="center top-13"
    />
  );
}

interface TimerControlProps {
  currentTime: number;
  duration: number;
  isAudio?: boolean;
}

export function TimerControl({
  currentTime,
  duration,
  isAudio,
}: TimerControlProps) {
  const isVideo = !isAudio;

  return (
    <div
      className={classNames(styles.timeDisplayContainer, {
        [styles.video]: isVideo,
      })}
    >
      <span className={styles.currentTime}>{formatTime(currentTime)}</span>
      <span className={styles.separator}> / </span>
      <span className={styles.duration}>{formatTime(duration)}</span>
    </div>
  );
}

interface TrackBarProps {
  duration: number;
  currentTime: number;
  seekTo: (time: number) => void;
  isAudio?: boolean;
}

export function TrackBarControl({
  duration,
  currentTime,
  seekTo,
  isAudio,
}: TrackBarProps) {
  const isVideo = !isAudio;
  return (
    <div className={classNames(styles.trackBar, { [styles.video]: isVideo })}>
      <TrackBar
        total={duration}
        current={currentTime}
        setCurrent={seekTo}
        trackBarColor={isVideo ? "#FFFFFF" : undefined}
      />
    </div>
  );
}

interface VolumeControlProps {
  volumeLevel: number;
  settingsMenuOpen: boolean;
  volumeBarOpen: boolean;
  setVolume: (vol: number) => void;
  openVolumeBar: () => void;
  closeVolumeBar: () => void;
  isAudio?: boolean;
}

export function VolumeControl({
  setVolume,
  volumeLevel,
  volumeBarOpen,
  openVolumeBar,
  closeVolumeBar,
  isAudio,
}: VolumeControlProps) {
  const savedVolume = useRef<number | null>(null);
  return (
    <div
      className={styles.VolumeControl}
      onMouseOver={openVolumeBar}
      onMouseLeave={closeVolumeBar}
    >
      <Button
        className={`${styles.volumeButton} tooltip`}
        icon={<RenderVolumeIcon isAudio={isAudio} volumeLevel={volumeLevel} />}
        onClick={() => {
          if (volumeLevel > 0) {
            savedVolume.current = volumeLevel;
            setVolume(0);
            return;
          }

          if (savedVolume.current) {
            setVolume(savedVolume.current);
          }
        }}
        data-pr-tooltip={volumeLevel > 0 ? "Mute (M)" : "Unmute (M)"}
        data-pr-position="top"
        data-pr-at="center top-13"
      />
      {volumeBarOpen && (
        <TrackBar
          current={volumeLevel}
          setCurrent={setVolume}
          total={100}
          className={styles.volumeTrackBar}
          closeVolumeBar={closeVolumeBar}
        />
      )}
    </div>
  );
}

interface SettingsControlProps {
  settingsMenuOpen: boolean;
  openSettingsMenu: () => void;
  isAudio?: boolean;
}

export function SettingsControl({
  settingsMenuOpen,
  openSettingsMenu,
  isAudio,
}: SettingsControlProps) {
  return (
    <div
      className={classNames(styles.settingsButtonContainer, {
        [styles.video]: !isAudio,
      })}
    >
      <Button
        className={`${styles.controlButton} tooltip`}
        icon={<Settings fillColor={isAudio ? "var(--text-body)" : undefined} />}
        onClick={(e) => {
          e.stopPropagation();
          openSettingsMenu();
        }}
        data-pr-tooltip="Settings"
        data-pr-position="top"
        data-pr-at="center top-13"
        data-pr-disabled={settingsMenuOpen}
      />
    </div>
  );
}
