import { AddEntityProps } from "../add-entity/AddEntityProps";
import { Dialog, DialogProps } from "primereact/dialog";
import { Button } from "primereact/button";
import { SessionActivityEditor } from "./SessionActivityEditor";
import { useEditActivity } from "./useEditActivity";

interface SessionActivityEditorDialogProps
  extends AddEntityProps,
    Omit<DialogProps, "header"> {
  activeSessionId: number;
  activeSessionActivityId?: number;
}

export const SessionActivityEditorDialog: React.FC<
  SessionActivityEditorDialogProps
> = (props: SessionActivityEditorDialogProps) => {
  const {
    visible,
    onHide,
    style,
    activeSessionId,
    activeSessionActivityId,
    onComplete,
  } = props;

  const {
    newActivity,
    editingActivity,
    handleSave,
    updateActivity,
    updateExerciseAndObjective,
  } = useEditActivity(activeSessionId, activeSessionActivityId);
  const mode: "add" | "edit" =
    activeSessionActivityId && editingActivity ? "edit" : "add";

  const saveAndExit = async () => {
    handleSave();
    onComplete(true);
  };

  const cancel = () => {
    onComplete(false);
  };
  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={cancel}
        className="p-button-text"
      />
      <Button
        label={mode === "add" ? "Add Activity" : "Save"}
        icon="pi pi-check"
        onClick={saveAndExit}
        autoFocus
      />
    </div>
  );

  const activityToRender = newActivity ?? editingActivity;

  if (mode === "edit" && !editingActivity) {
    return <p>Loading activity details...</p>;
  }

  if (mode === "add" && !newActivity) {
    return <p>Preparing new activity...</p>;
  }

  return (
    <Dialog
      visible={visible}
      header={mode === "add" ? "Add Activity" : "Edit Activity"}
      onHide={onHide}
      style={{ width: "80vw", height: "80vh", ...style }}
      footer={footerContent}
    >
      {activityToRender ? (
        <SessionActivityEditor
          activeSessionId={activeSessionId}
          initialActivity={activityToRender}
          updateActivity={updateActivity}
          updateExerciseAndObjective={updateExerciseAndObjective}
        />
      ) : (
        <p>Loading...</p>
      )}
    </Dialog>
  );
};
