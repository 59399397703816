import { useEffect, useRef } from "react";
import { AudioVisualizer } from "react-audio-visualize";
import { SettingsMenu } from "./SettingsMenu";
import { Tooltip } from "../menu/Tooltip";
import styles from "./MediaPlayer.module.css";
import { UploadBlob } from "../../storage";
import useMediaPlayer from "./useMediaPlayer";
import {
  menuItems,
  PlayControl,
  SettingsControl,
  TimerControl,
  TrackBarControl,
  VolumeControl,
} from "./MediaPlayerElements";
import { useMediaQuery } from "react-responsive";

interface AudioPlayerProps {
  capturedAudio: UploadBlob;
  showAudioVisualizer: boolean | null;
}

export const AudioPlayer = ({
  capturedAudio,
  showAudioVisualizer,
}: AudioPlayerProps): JSX.Element => {
  const visualizerRef = useRef<HTMLCanvasElement>(null);
  const audioRef = useRef<HTMLAudioElement>(new Audio());

  const {
    isPlaying,
    play,
    pause,
    currentTime,
    duration,
    setDuration,
    seekTo,
    volumeLevel,
    setVolume,
    volumeBarOpen,
    openVolumeBar,
    closeVolumeBar,
    settingsMenuOpen,
    openSettingsMenu,
    closeSettingsMenu,
    settingsState,
    updateSettingsState,
  } = useMediaPlayer({
    mediaRef: audioRef,
  });

  useEffect(() => {
    const audio = audioRef?.current;
    (async () => {
      if (audio) {
        audio.src = URL.createObjectURL(capturedAudio);
        audio.loop = false;
        audio.volume = 75 / 100;
        audio.muted = false;
        audio.autoplay = false;
        audio.crossOrigin = null;
        audio.preload = "";
        audio.playbackRate = 1.0;

        audio.addEventListener(
          "ended",
          () => {
            pause();
            seekTo(0);
          },
          { once: true },
        );
      }

      const audioBuffer = await capturedAudio.arrayBuffer();
      const audioContext = new AudioContext();
      await audioContext.decodeAudioData(audioBuffer, (buffer) => {
        setDuration(buffer.duration);
      });
    })();

    return () => {
      URL.revokeObjectURL(audio.src);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capturedAudio]);

  const isSmallScreen = useMediaQuery({ maxWidth: 422 });

  return (
    <div className={styles.topContainer}>
      {showAudioVisualizer && (
        <AudioVisualizer
          ref={visualizerRef}
          blob={capturedAudio}
          width={500}
          height={75}
          barWidth={1}
          gap={0}
          barColor={"#f76565"}
          barPlayedColor={"#000000"}
          currentTime={currentTime}
        />
      )}
      {settingsMenuOpen && (
        <SettingsMenu
          settingsState={settingsState}
          menuItems={menuItems.filter(
            (item) => !["quality", "subtitles"].includes(item.name),
          )}
          updateSettingsState={updateSettingsState}
          closeSettingsMenu={closeSettingsMenu}
          posRight={0}
          posBottom={42}
        />
      )}
      {duration > 0 && (
        <div className={styles.container}>
          <Tooltip hideOnTargetClick target=".tooltip" />
          <div className={styles.playbackContainer}>
            <div className={styles.playbackButtonContainer}>
              <PlayControl
                isPlaying={isPlaying}
                onPause={pause}
                onPlay={play}
                isAudio={true}
              />
            </div>
            {!(isSmallScreen && volumeBarOpen) && (
              <TimerControl
                currentTime={currentTime}
                duration={duration}
                isAudio={true}
              />
            )}
          </div>
          <TrackBarControl
            duration={duration}
            currentTime={currentTime}
            seekTo={seekTo}
            isAudio={true}
          />
          <div className={styles.volumeAndSettingsControl}>
            <VolumeControl
              setVolume={setVolume}
              volumeLevel={volumeLevel}
              volumeBarOpen={volumeBarOpen}
              openVolumeBar={openVolumeBar}
              closeVolumeBar={closeVolumeBar}
              settingsMenuOpen={settingsMenuOpen}
              isAudio={true}
            />
            <SettingsControl
              settingsMenuOpen={settingsMenuOpen}
              openSettingsMenu={openSettingsMenu}
              isAudio={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};
