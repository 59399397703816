import React, {
  type ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./TrackBar.module.css";
import { classNames } from "primereact/utils";

interface TrackBarProps {
  current: number;
  setCurrent: (c: number) => void;
  total: number;
  showTrack?: boolean;
  showKnob?: boolean;
  trackHeight?: number;
  trackBarColor?: string;
  closeVolumeBar?: () => void;
  className?: string | undefined;
  color?: string;
}

const getPercentage = (current: number, total: number): string => {
  const percentage = (current / total) * 100;
  return `${percentage}%`;
};

const getClientX = (event: MouseEvent | TouchEvent): number => {
  if ("touches" in event && event.touches.length > 0) {
    return event.touches[0].clientX;
  }
  return (event as MouseEvent).clientX;
};

const TrackBar = ({
  current,
  setCurrent,
  total,
  showTrack = true,
  showKnob = false,
  trackHeight = 4,
  closeVolumeBar,
  className,
  trackBarColor = "var(--text-body)",
}: TrackBarProps): ReactElement => {
  const [isHover, setIsHover] = useState(false);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const volumeBarTimer = useRef<ReturnType<typeof setInterval> | null>(null);

  const setPosition = useCallback((event: MouseEvent | TouchEvent): void => {
    if (!progressBarRef.current) return;

    const progressBarRect = progressBarRef.current.getBoundingClientRect();
    const maxRelativePos = progressBarRect.width;
    const relativePos = getClientX(event) - progressBarRect.left;

    if (relativePos < 0) {
      setCurrent(0);
      return;
    }

    if (relativePos > maxRelativePos) {
      setCurrent(total);
      return;
    }

    setCurrent((total * relativePos) / maxRelativePos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stopVolumeBarTimer = useCallback(() => {
    volumeBarTimer?.current && clearTimeout(volumeBarTimer?.current);
  }, []);

  const handleMouseEnter = useCallback((): void => setIsHover(true), []);

  const handleMouseLeave = useCallback((): void => {
    setIsHover(false);
    volumeBarTimer.current = setTimeout(() => {
      closeVolumeBar && closeVolumeBar();
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMouseOrTouchMove = useCallback(
    (event: MouseEvent | TouchEvent): void => {
      setPosition(event);
      stopVolumeBarTimer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onMouseDownOrTouchStart = useCallback(
    (event: React.MouseEvent | React.TouchEvent): void => {
      if (event.nativeEvent instanceof MouseEvent) {
        window.addEventListener("mousemove", onMouseOrTouchMove);
        window.addEventListener("mouseup", onMouseOrTouchUp);
      } else {
        window.addEventListener("touchmove", onMouseOrTouchMove);
        window.addEventListener("touchend", onMouseOrTouchUp);
      }

      setPosition(event.nativeEvent);
      setIsHover(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onMouseOrTouchUp = useCallback(
    (event: MouseEvent | TouchEvent): void => {
      if (event instanceof MouseEvent) {
        window.removeEventListener("mousemove", onMouseOrTouchMove);
        window.removeEventListener("mouseup", onMouseOrTouchUp);
      } else {
        window.removeEventListener("touchmove", onMouseOrTouchMove);
        window.removeEventListener("touchend", onMouseOrTouchUp);
      }
      setIsHover(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const percentage = useMemo(
    () => getPercentage(current, total),
    [current, total],
  );

  return (
    <div
      tabIndex={0}
      className={classNames(styles.container, className)}
      onMouseDown={onMouseDownOrTouchStart}
      onTouchStart={onMouseDownOrTouchStart}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={progressBarRef}
    >
      <div
        className={styles.track_bar}
        style={{
          background: `linear-gradient(to right, ${trackBarColor} ${percentage}, color-mix(in srgb, ${trackBarColor} 10%, transparent) ${percentage})`,
          height: showTrack ? trackHeight : 0,
        }}
      >
        <div
          className={classNames(
            styles.track_knob,
            (showKnob || isHover) && styles.showKnob,
          )}
          style={{ background: trackBarColor, left: percentage }}
        />
      </div>
    </div>
  );
};

export default TrackBar;
