import { useEffect } from "react";

const useDisableBodyScroll = (condition: boolean) => {
  useEffect(
    function disableScroll() {
      if (condition) {
        document.body.style.overflow = "hidden";
      }

      return () => {
        document.body.style.overflow = "unset";
      };
    },
    [condition],
  );
};

export default useDisableBodyScroll;
