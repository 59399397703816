import { z } from "zod";

export enum RatingEnum {
  NeedsWork = "needs work",
  Satisfactory = "satisfactory",
  Good = "good",
  Excellent = "excellent",
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export const RatingEnumSchema = z.nativeEnum(RatingEnum);

export type Rating = z.infer<typeof RatingEnumSchema>;

export const activityInterstitialSchema = z.object({
  isStart: z.boolean().default(true),
  hideWhenSeen: z.boolean().default(false),
  model: z.object({ title: z.string(), content: z.string() }),
});

export type ActivityInterstitial = z.infer<typeof activityInterstitialSchema>;
