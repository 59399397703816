import { EditSessionActivityProps } from "./types";
import { trpc, ActivityType } from "@arena-active/trpc-client";
import { useEffect, useState } from "react";
import { PromptObjectiveEditor } from "../crud/PromptObjectiveEditor";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RatingEnum } from "@arena-active/schema";
import { ExerciseType, PartialLearningObjective } from "./types";

const getDefaultRubricRatings = () => {
  return Object.values(RatingEnum).map((rating) => ({
    rating,
    description: "",
  }));
};

export interface ExerciseAndObjective {
  exercise?: ExerciseType;
  objective?: PartialLearningObjective;
}

interface EditExerciseActivityProps extends EditSessionActivityProps {
  onUpdateExerciseAndObjective: (
    exerciseAndObjective: ExerciseAndObjective,
  ) => void;
}

export const EditExerciseActivity: React.FC<EditExerciseActivityProps> = ({
  activity,
  save,
  onUpdateExerciseAndObjective,
}) => {
  const utils = trpc.useUtils();
  const [exerciseId, setExerciseId] = useState<number | undefined>(
    activity.exerciseId,
  );
  const [isExerciseCreated, setIsExerciseCreated] = useState(false);
  const [exercise, setExercise] = useState<ExerciseType>({
    title: null,
    prompt: {
      prompt: null,
      learningObjectiveId: null,
    },
  });

  const { data: remoteExercise } = trpc.exercise.get.useQuery(
    { id: exerciseId as number },
    {
      enabled: exerciseId !== undefined,
    },
  );

  useEffect(() => {
    if (remoteExercise) {
      setExercise(remoteExercise);
    }
  }, [remoteExercise]);

  useEffect(() => {
    onUpdateExerciseAndObjective?.({ exercise });
  }, [exercise]);

  const createExerciseMutation = trpc.exercise.create.useMutation();
  const saveExercise = async () => {
    if (exercise && !exercise.id) {
      const savedExercise = await createExerciseMutation.mutateAsync({
        title: exercise.title,
        prompt: {
          create: {
            prompt: exercise.prompt?.prompt ?? "",
            learningObjective: {
              create: {
                summary: "test summary",
                description: "test description",
                rubric: {
                  create: {
                    ratings: {
                      create: getDefaultRubricRatings(),
                    },
                  },
                },
              },
            },
          },
        },
      });
      setExercise(savedExercise);
      setExerciseId(savedExercise.id);
      save({
        activityType: ActivityType.EXERCISE,
        activeSessionId: activity.activeSessionId,
        exerciseId: savedExercise.id,
      });
      await utils.activeSession.get.invalidate({
        id: activity.activeSessionId,
      });
      setIsExerciseCreated(true);
    }
  };

  if (exerciseId && !exercise) {
    return <div>loading...</div>;
  }

  return (
    <div>
      <Fieldset collapsed={false} toggleable legend={`Exercise`}>
        <InputText
          placeholder="Title"
          value={exercise?.title ?? ""}
          onChange={(e) => setExercise({ ...exercise, title: e.target.value })}
        />
      </Fieldset>
      <Fieldset collapsed={false} toggleable legend={`Question Prompt`}>
        <InputText
          placeholder="Question Prompt"
          value={exercise?.prompt?.prompt ?? ""}
          style={{ width: "100%" }}
          onChange={(e) =>
            setExercise({
              ...exercise,
              prompt: {
                ...exercise.prompt,
                prompt: e.target.value,
              },
            })
          }
        />
      </Fieldset>
      {!isExerciseCreated && !exercise.id && (
        <Button
          disabled={!exercise?.title}
          style={{ flexShrink: "0", width: "fit-content" }}
          label={exercise.id ? "Update Exercise" : "Create Exercise"}
          onClick={saveExercise}
        />
      )}
      {exercise?.prompt && exercise.prompt.learningObjectiveId && (
        <div>
          <PromptObjectiveEditor
            objectiveId={exercise.prompt.learningObjectiveId}
            onUpdate={(newObjective: PartialLearningObjective) => {
              onUpdateExerciseAndObjective?.({ objective: newObjective });
            }}
          />
        </div>
      )}
    </div>
  );
};
