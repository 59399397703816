import { useMemo, useRef, useState } from "react";
import { RightArrow, LeftArrow, Check } from "../common/Icons";
import { get, truncate } from "lodash";
import { useClickAway } from "use-click-away";
import { Button } from "../common/Button";
import styles from "./SettingsMenu.module.css";
import { classNames } from "primereact/utils";

export type Option = {
  id: string;
  label: string;
};

export type MenuItem<TOpt extends Option> = {
  name: string;
  icon: React.ReactElement;
  label: string;
  value: string;
  options: TOpt[];
};

interface SettingsMenuProps<TOpt extends Option> {
  settingsState: Record<string, TOpt>;
  updateSettingsState: (menuName: string, option: TOpt) => void;
  closeSettingsMenu: () => void;
  menuItems: Array<MenuItem<TOpt>> | null;
  onSwitchToText?: () => void;
  posTop?: string | number;
  posLeft?: string | number;
  posBottom?: string | number;
  posRight?: string | number;
}

export const SettingsMenu = <TOpt extends Option>({
  settingsState,
  updateSettingsState,
  closeSettingsMenu,
  menuItems,
  onSwitchToText,
  posTop = "unset",
  posLeft = "unset",
  posBottom = "unset",
  posRight = "unset",
}: SettingsMenuProps<TOpt>) => {
  const clickRef = useRef(null);

  useClickAway(clickRef, () => {
    closeSettingsMenu();
  });

  const [menuItemSelected, setMenuItemSelected] = useState<MenuItem<TOpt>>();

  const selectedId = useMemo(
    () =>
      menuItemSelected ? settingsState[menuItemSelected?.name]?.id : undefined,
    [settingsState, menuItemSelected],
  );

  const handleClose = () => {
    closeSettingsMenu();
    setMenuItemSelected(undefined);
  };

  const MenuList = (
    <ul className={styles.menuList}>
      {(menuItems || []).map((item) => {
        const selectedMenuItemLabel: string = String(
          get(settingsState, `[${item?.name}].label`) || "",
        );
        return (
          <li
            key={item?.name}
            onClick={() => setMenuItemSelected(item)}
            className={styles.menuItemContainer}
          >
            <span className={styles.menuItemIcon}>{item.icon}</span>
            <span className={styles.menuItemLabel}>{item.label}</span>
            <div className={styles.selectedLabelContainer}>
              <span className={styles.selectedLabel}>
                {truncate(selectedMenuItemLabel, { length: 20 })}
              </span>
              <RightArrow />
            </div>
          </li>
        );
      })}
    </ul>
  );

  const SelectedMenuItem = (
    <ul className={styles.selectedMenuList}>
      <li
        onClick={() => setMenuItemSelected(undefined)}
        className={styles.selectedMenuListItem}
      >
        <LeftArrow />
        <span className={styles.ml2}>{menuItemSelected?.label}</span>
      </li>
      {(menuItemSelected?.options || []).map((option) => (
        <li
          key={option?.id}
          onClick={() => {
            if (menuItemSelected?.name) {
              updateSettingsState(menuItemSelected?.name, option);
            }
            handleClose();
          }}
          className={styles.selectedItem}
        >
          {selectedId === option.id && <Check />}
          <span className={styles.ml2}>{option.label}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <div
      ref={clickRef}
      className={classNames(styles.container)}
      style={{
        top: posTop,
        bottom: posBottom,
        left: posLeft,
        right: posRight,
      }}
    >
      {menuItemSelected ? SelectedMenuItem : MenuList}

      {onSwitchToText && (
        <div className={styles.switchToTextContainer}>
          <hr />
          <div>Can't use your Microphone?</div>
          <Button
            label="Switch to text"
            type="SECONDARY_FILLED"
            onClick={onSwitchToText}
          />
        </div>
      )}
    </div>
  );
};
