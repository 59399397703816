import { PlayCircle } from "../common/Icons";
import simpleVideoPlayerStyles from "./SimpleVideoPlayer.module.css";
import { VideoPlayer } from "./VideoPlayer";
import useModal from "../common/hooks/useModal";
import { Modal } from "../common/Modal";

interface SimpleVideoPlayerProps {
  source?: string;
  type?: string;
}

export function SimpleVideoPlayer({ source, type }: SimpleVideoPlayerProps) {
  const { isShowing, toggle } = useModal();

  if (!source) {
    return null;
  }

  return (
    <>
      <div className={simpleVideoPlayerStyles.videoPlayer}>
        <video src={source} />
        <div className={simpleVideoPlayerStyles.overlay} onClick={toggle}>
          <PlayCircle fillColor="#fff" />
        </div>
      </div>
      <Modal title="My Recording" show={isShowing} onCloseButtonClick={toggle}>
        <VideoPlayer source={source} type={type} />
      </Modal>
    </>
  );
}
