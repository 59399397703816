import { EditSessionActivityProps } from "./types";
import { useEffect } from "react";
import { LessonSelector } from "../LessonSelector";
import { ActivityType, PrismaModels } from "@arena-active/trpc-client";
import { InputText } from "primereact/inputtext";
import { useDebounce } from "primereact/hooks";
import type { UploadActivity } from "@arena-active/api";
import { Card } from "primereact/card";
export const EditUploadActivity: React.FC<EditSessionActivityProps> = ({
  activity,
  save,
}) => {
  const config: UploadActivity = activity.activityConfig
    ? activity.activityConfig
    : {
        type: ActivityType.UPLOAD,
        textUrl: null,
        title: null,
      };

  type Lesson = PrismaModels["Lesson"];

  const [title, debouncedTitle, setTitle] = useDebounce<string | null>(
    config.title,
    2000,
  );
  const [textUrl, debouncedTextUrl, setTextUrl] = useDebounce<string | null>(
    config.textUrl,
    2000,
  );

  function onDropdownChange(value: Lesson) {
    if (value.lessonPageUrl) {
      setTextUrl(value.lessonPageUrl);
    }
  }

  useEffect(() => {
    if (debouncedTextUrl) {
      save({
        ...activity,
        activityConfig: {
          textUrl: debouncedTextUrl,
          title: debouncedTitle,
          type: ActivityType.UPLOAD,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTitle, debouncedTextUrl]);

  return (
    <div>
      <Card
        title="Upload"
        subTitle="Enter a title and a url for text page that shows instructions. Use the selector to find an existing lesson to populate text url field."
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputText
            value={title ?? ""}
            placeholder="Title"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <InputText
            value={textUrl ?? ""}
            placeholder="Leson Page Url"
            onChange={(e) => {
              setTextUrl(e.target.value);
            }}
          />
          <LessonSelector
            onlyPages={true}
            onSelectionChange={(lesson) => {
              lesson && onDropdownChange(lesson);
            }}
          />
        </div>
      </Card>
    </div>
  );
};
