import { trpc } from "@arena-active/trpc-client";
import type {
  UpdateActiveSessionActivityInput,
  AppendActiveSessionActivityInput,
} from "@arena-active/trpc-client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ExerciseAndObjective } from "./EditExerciseActivity";
import { ExerciseType, PartialLearningObjective } from "./types";

export const useEditActivity = (
  activeSessionId: number,
  activeSessionActivityId?: number,
) => {
  const utils = trpc.useUtils();
  const { data: loadedActivity, refetch } =
    trpc.activeSession.getActivity.useQuery(
      { id: activeSessionActivityId! },
      {
        enabled: !!activeSessionActivityId,
      },
    );

  const emptyActivity: AppendActiveSessionActivityInput = useMemo(
    () => ({
      activeSessionId,
      lessonId: undefined,
      exerciseId: undefined,
    }),
    [activeSessionId],
  );

  const [newActivity, setNewActivity] =
    useState<AppendActiveSessionActivityInput | null>(null);
  const [editingActivity, setEditingActivity] =
    useState<UpdateActiveSessionActivityInput | null>(null);

  const [exercise, setExercise] = useState<ExerciseType | undefined>(undefined);
  const [objective, setObjective] = useState<
    PartialLearningObjective | undefined
  >(undefined);

  const updateExerciseMutation = trpc.exercise.update.useMutation();
  const updateObjectiveMutation = trpc.objective.updateObjective.useMutation();

  useEffect(() => {
    if (activeSessionActivityId) {
      setNewActivity(null);
      setEditingActivity(null);
      refetch();
    } else {
      setNewActivity(emptyActivity);
      setEditingActivity(null);
    }
  }, [activeSessionActivityId, emptyActivity, refetch]);

  useEffect(() => {
    if (
      loadedActivity &&
      typeof loadedActivity.id === "number" &&
      typeof loadedActivity.activeSessionId === "number"
    ) {
      setEditingActivity(loadedActivity as UpdateActiveSessionActivityInput);
    }
  }, [loadedActivity]);

  const appendActivityMutation =
    trpc.activeSession.appendActivity.useMutation();
  const updateActivityMutation =
    trpc.activeSession.updateActivity.useMutation();

  const updateActivity = useCallback(
    (
      newData:
        | AppendActiveSessionActivityInput
        | UpdateActiveSessionActivityInput,
    ) => {
      if ("id" in newData) {
        setEditingActivity(newData as UpdateActiveSessionActivityInput);
      } else {
        setNewActivity(newData as AppendActiveSessionActivityInput);
      }
    },
    [],
  );

  const saveActivity = useCallback(
    async (
      activity:
        | AppendActiveSessionActivityInput
        | UpdateActiveSessionActivityInput,
    ) => {
      try {
        if ("id" in activity && activity.id !== undefined) {
          await updateActivityMutation.mutateAsync({
            ...activity,
            activityType: activity.activityType ?? undefined,
          });
        } else {
          await appendActivityMutation.mutateAsync({
            ...activity,
            activityType: activity.activityType ?? undefined,
          });
        }
        utils.activeSession.get.invalidate({ id: activeSessionId });
      } catch (error) {
        console.error("Failed to save activity", error);
      }
    },
    [activeSessionId],
  );

  const updateExerciseAndObjective = useCallback(
    (newData: ExerciseAndObjective) => {
      if (newData.exercise) {
        setExercise(newData.exercise);
      }
      if (newData.objective) {
        setObjective(newData.objective);
      }
    },
    [],
  );

  const handleSave = async () => {
    if (exercise && exercise.id) {
      await updateExerciseMutation.mutateAsync({
        id: exercise.id,
        data: {
          title: exercise.title,
          prompt: {
            update: {
              prompt: exercise.prompt?.prompt ?? "",
            },
          },
        },
      });
    }

    if (objective?.id) {
      try {
        const summary = objective?.summary || "Default Summary";
        const description = objective?.description || "Default Description";
        await updateObjectiveMutation.mutateAsync({
          id: objective.id,
          data: {
            summary,
            description,
            lesson: objective?.lesson?.id
              ? { connect: { id: objective.lesson.id } }
              : undefined,
            rubric: {
              update: {
                ratings: {
                  updateMany: objective?.rubric?.ratings?.map((rating) => ({
                    where: { id: rating.id },
                    data: {
                      rating: rating.rating,
                      description: rating.description,
                    },
                  })),
                },
              },
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (newActivity) {
      await saveActivity(newActivity);
    } else if (editingActivity) {
      const nonNullEditingActivity = Object.fromEntries(
        Object.entries(editingActivity).filter(([, v]) => v != null),
      );
      utils.activeSession.get.invalidate({
        id: nonNullEditingActivity.activeSessionId,
      });
      await saveActivity(
        nonNullEditingActivity as UpdateActiveSessionActivityInput,
      );
    }
  };

  return {
    saveActivity,
    handleSave,
    updateActivity,
    newActivity,
    editingActivity,
    updateExerciseAndObjective,
  };
};
