import type { ActivityType as PrismaActivityType } from "@arena-active/api";

/**
 * The types of activity.
 * These are checked at compile time against the source of truth: Prisma activity types enum
 * to ensure that they match.
 */
export const ActivityType = {
  LESSON_PAGE: "LESSON_PAGE",
  EXERCISE: "EXERCISE",
  VIDEO: "VIDEO",
  UPLOAD: "UPLOAD",
  CHAT: "CHAT",
} as const;

/**
 * Note: the below is some Type gymnastics to ensure that client side activity
 * types conform to the origin of their definition as a prisma schema enum.
 * We don't want to import the prisma enum directly in the client codebase
 */
export type ActivityType = (typeof ActivityType)[keyof typeof ActivityType];

// Utility type to check if two types are exactly the same
type AreTypesEqual<A, B> = (<T>() => T extends A ? 1 : 2) extends <
  T,
>() => T extends B ? 1 : 2
  ? true
  : false;

// Use the utility type to compare ActivityType and PrismaActivityType
type EnsureActivityTypesAreEqual = AreTypesEqual<
  ActivityType,
  PrismaActivityType
> extends true
  ? true
  : [
      "Error: ActivityTypes do not match",
      Exclude<
        ActivityType | PrismaActivityType,
        ActivityType & PrismaActivityType
      >,
    ];

// This line will cause a TypeScript error if ActivityType and PrismaActivityType are not equal
const _ensureActivityTypesAreEqual: EnsureActivityTypesAreEqual = true;
// to satisfy ts compiler. This const is intentionally not used
void _ensureActivityTypesAreEqual;
