import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import {
  activityInterstitialSchema,
  type ActivityInterstitial,
} from "@arena-active/schema";
import { Card } from "primereact/card";
import { InputTextarea } from "primereact/inputtextarea";

type EditActivityInterstitialProps = {
  initialData: ActivityInterstitial;
  onUpdate: (updatedData: ActivityInterstitial) => void;
};

export const EditActivityInterstitial: React.FC<
  EditActivityInterstitialProps
> = ({ initialData, onUpdate }) => {
  const [title, setTitle] = useState(initialData?.model?.title);
  const [content, setContent] = useState(initialData?.model?.content);
  const [isStart] = useState(initialData?.isStart);
  const [hideWhenSeen] = useState(initialData?.hideWhenSeen);

  // Keep a ref of the previous data for comparison
  const previousDataRef = useRef(initialData);

  useEffect(() => {
    const updatedData = activityInterstitialSchema.parse({
      isStart,
      hideWhenSeen,
      model: { title, content },
    });

    const previousData = previousDataRef.current;
    const hasChanged =
      title !== previousData.model.title ||
      content !== previousData.model.content ||
      isStart !== previousData.isStart ||
      hideWhenSeen !== previousData.hideWhenSeen;

    if (hasChanged) {
      onUpdate(updatedData);
      previousDataRef.current = {
        ...previousData,
        model: { title, content },
      };
    }
  }, [title, content, isStart, hideWhenSeen, onUpdate]);

  return (
    <div>
      <Card title={isStart ? "Pre" : "Post"}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InputText
            placeholder="Title"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <InputTextarea
            placeholder="Content"
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
      </Card>
    </div>
  );
};
