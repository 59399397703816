import { ProgressBar } from "primereact/progressbar";
import { ActivityType, trpc } from "@arena-active/trpc-client";
import { OrderList } from "primereact/orderlist";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { SessionActivityEditorDialog } from "./session-activity/SessionActivityEditorDialog";

interface ActiveSessionActivitiesProps {
  sessionId: number;
}

export const ActiveSessionActivities: React.FC<
  ActiveSessionActivitiesProps
> = ({ sessionId }) => {
  const utils = trpc.useUtils();
  const [showAddActivityDialog, setShowAddActivityDialog] = useState(false);
  const [selectedActiveSessionActivityId, setSelectedActiveSessionActivityId] =
    useState<number | undefined>();

  const {
    data: sessionData,
    isLoading,
    isError,
  } = trpc.activeSession.get.useQuery({ id: sessionId });

  const deleteActivityMutation =
    trpc.activeSession.deleteActivity.useMutation();
  const reorderActivitiesMutation =
    trpc.activeSession.reOrderActivities.useMutation();

  type Activity = {
    id: number;
    order: number;
    exercise?: { title: string };
    lesson?: { title: string };
    activityType: ActivityType;
    activityConfig?: { title: string };
  };

  const [activities, setActivities] = useState<Activity[]>([]);

  type SessionActivityType = {
    id: number;
    order: number;
    exercise?: { title?: string };
    lesson?: { title?: string };
    activityType: ActivityType;
    activityConfig?: { title: string };
  };

  useEffect(() => {
    if (sessionData?.activities) {
      const activitiesData =
        sessionData.activities as unknown as SessionActivityType[];

      const transformedActivities: Activity[] = activitiesData.map(
        (activity): Activity => ({
          id: activity.id,
          order: activity.order,
          exercise: activity.exercise?.title
            ? { title: activity.exercise.title }
            : undefined,
          lesson: activity.lesson?.title
            ? { title: activity.lesson.title }
            : undefined,
          activityType: activity.activityType,
          activityConfig: activity.activityConfig,
        }),
      );
      setActivities(transformedActivities);
    }
  }, [sessionData]);

  const handleReorder = async (newActivities: Activity[]) => {
    const hasChanged = newActivities.some(
      (activity, index) => activity.order !== index + 1,
    );

    if (hasChanged) {
      const reorderedData = newActivities.map((activity, index) => ({
        id: activity.id,
        order: index + 1,
      }));

      try {
        await reorderActivitiesMutation.mutateAsync(reorderedData);
        utils.activeSession.get.invalidate();
      } catch (error) {
        console.error("Failed to reorder activities:", error);
      }
    }
  };

  const onChangeOrder = (e: { value: Activity[] }) => {
    const newOrder = e.value;
    setActivities(newOrder);
    handleReorder(newOrder);
  };

  const removeActivity = async (activityId: number) => {
    try {
      if (activityId) {
        await deleteActivityMutation.mutateAsync({ id: activityId });
        utils.activeSession.get.invalidate();
      }
    } catch (error) {
      console.error("Failed to remove activity:", error);
    }
  };

  const editActivity = (activityId: number) => {
    setSelectedActiveSessionActivityId(activityId);
    setShowAddActivityDialog(true);
  };

  const itemTemplate = (activity: (typeof activities)[number]) => (
    <div className="flex flex-wrap p-2 align-items-center gap-3">
      {activity.exercise && (
        <span className="font-light text-400">{activity.exercise.title}</span>
      )}
      {activity.lesson && (
        <span className="font-light text-400">{activity.lesson.title}</span>
      )}

      {activity.activityType === "UPLOAD" && (
        <span className="font-light text-400">
          {activity.activityConfig?.title}
        </span>
      )}
      <Button
        onClick={() => removeActivity(activity.id)}
        icon="pi pi-times"
        rounded
        text
        severity="warning"
        aria-label="remove activity"
      />
      <Button
        onClick={() => editActivity(activity.id)}
        icon="pi pi-file-edit"
        rounded
        text
        severity="info"
        aria-label="edit activity"
      />
    </div>
  );

  if (isLoading) {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  }

  if (isError) {
    return <div>Error loading</div>;
  }

  if (!sessionId) {
    return null;
  }

  return (
    <>
      <SessionActivityEditorDialog
        key={selectedActiveSessionActivityId}
        visible={showAddActivityDialog}
        onHide={() => setShowAddActivityDialog(false)}
        activeSessionId={sessionId}
        activeSessionActivityId={selectedActiveSessionActivityId}
        onComplete={(added) => {
          setShowAddActivityDialog(false);
          if (added) {
            utils.activeSession.get.invalidate();
          }
        }}
      />
      <div className="mt-3">
        <OrderList
          dataKey="id"
          value={activities}
          onChange={onChangeOrder}
          itemTemplate={itemTemplate}
          header={`activities ${
            activities.length === 0 ? "(none added)" : `(${activities.length})`
          }`}
        />
      </div>
      <div className="flex align-items-center gap-2 mt-3">
        <Button
          icon="pi pi-external-link"
          label="Create Activity"
          className="flex-grow-0"
          onClick={() => {
            setSelectedActiveSessionActivityId(undefined);
            setShowAddActivityDialog(true);
          }}
        />
      </div>
    </>
  );
};
