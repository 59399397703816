import { trpc } from "@arena-active/trpc-client";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { DeepPartial } from "@arena-active/schema";
import { Card } from "primereact/card";
import { Fieldset } from "primereact/fieldset";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEditorOptions, ColumnEvent } from "primereact/column";
import { LessonSelector } from "../LessonSelector";
import { LearningObjectiveWithRelations } from "@arena-active/api";

interface ObjectiveEditorProps {
  objectiveId: number;
  onUpdate: (newObjective: PartialLearningObjective) => void;
}

type PartialLearningObjective = DeepPartial<LearningObjectiveWithRelations>;

export const PromptObjectiveEditor: React.FC<ObjectiveEditorProps> = ({
  objectiveId,
  onUpdate,
}) => {
  const [id] = useState<number>(objectiveId);
  const [newObjective, setNewObjective] =
    useState<PartialLearningObjective | null>(null);

  const {
    data: remoteObjective,
    isLoading,
    isError,
  } = trpc.objective.get.useQuery({ id }, { enabled: !!id });

  useEffect(() => {
    if (remoteObjective) {
      setNewObjective(remoteObjective as PartialLearningObjective);
    } else {
      setNewObjective(null);
    }
  }, [remoteObjective]);

  useEffect(() => {
    if (newObjective) {
      onUpdate(newObjective);
    }
  }, [newObjective]);

  const handleInputChange = (
    field: keyof LearningObjectiveWithRelations,
    value: string,
  ) => {
    setNewObjective((prevData) => {
      if (!prevData) return prevData;
      return {
        ...prevData,
        [field]: value,
      };
    });
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    const { rowData, newValue } = e;
    setNewObjective((prevData) => {
      if (!prevData || !prevData.rubric?.ratings) return prevData;
      const newRatings = prevData.rubric.ratings.map((rating) => ({
        ...rating,
        description: rowData.id === rating.id ? newValue : rating.description,
      }));
      return {
        ...prevData,
        rubric: { ...prevData.rubric, ratings: newRatings },
      };
    });
  };

  const textEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        type="text"
        style={{ width: "100%" }}
        value={options.value}
        onChange={(e) =>
          options?.editorCallback && options.editorCallback(e.target.value)
        }
      />
    );
  };

  if (isLoading) {
    return <div>Loading objective...</div>;
  }

  if (isError || !newObjective) {
    return <div>Error loading objective.</div>;
  }

  return (
    <Card>
      <Fieldset collapsed={false} toggleable legend={`Objective`}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <InputText
            placeholder="Summary"
            value={newObjective.summary ?? ""}
            onChange={(e) => handleInputChange("summary", e.target.value)}
          />
          <InputTextarea
            placeholder="Description"
            value={newObjective.description ?? ""}
            onChange={(e) => handleInputChange("description", e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
      </Fieldset>
      <Fieldset legend="Rubric" toggleable collapsed={true}>
        <DataTable
          editMode="cell"
          value={newObjective.rubric?.ratings}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column key="rating" field="rating" header="Rating"></Column>
          <Column
            key="description"
            field="description"
            header="Description"
            editor={(options) => textEditor(options)}
            onCellEditComplete={onCellEditComplete}
          ></Column>
        </DataTable>
      </Fieldset>
      <Fieldset collapsed={true} toggleable legend={`Lesson`}>
        <LessonSelector
          selectedLessonId={newObjective?.lesson?.id}
          onSelectionChange={(lesson) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setNewObjective((prevData: any) => {
              if (!prevData) return prevData;
              return {
                ...prevData,
                lesson: lesson && { id: lesson.id },
              };
            });
          }}
        />
      </Fieldset>
    </Card>
  );
};
