import React, { useState, useEffect, useCallback, useMemo } from "react";
import AsyncSelect from "react-select/async";
import { standaloneTRPCClient } from "@arena-active/trpc-client";
import { API_URL } from "../config";
import { AppRouter } from "@arena-active/api";
import { inferProcedureOutput } from "@arena-active/trpc-client";
import { debounce } from "@arena-active/client-lib";

const trpcClient = standaloneTRPCClient(API_URL);
type Lessons = inferProcedureOutput<AppRouter["lesson"]["find"]>;
type Lesson = Lessons[number];

type LessonSelectorProps = {
  onSelectionChange: (value: Lesson | null) => void;
  selectedLessonId?: number;
  onlyPages?: boolean;
};

export const LessonSelector: React.FC<LessonSelectorProps> = ({
  onSelectionChange,
  selectedLessonId,
  onlyPages = false,
}) => {
  const [selectedLesson, setSelectedLesson] = useState<Lesson | null>(null);

  useEffect(() => {
    const fetchSelectedLesson = async () => {
      if (selectedLessonId) {
        try {
          const data = await trpcClient.lesson.get.query({
            id: selectedLessonId,
          });
          if (data.lesson) {
            setSelectedLesson(data.lesson);
          }
        } catch (error) {
          console.error("Failed to fetch the selected lesson:", error);
        }
      } else {
        setSelectedLesson(null);
      }
    };
    fetchSelectedLesson();
  }, [selectedLessonId]);

  // Create the debounced function once using useMemo
  const fetchOptions = useMemo(
    () =>
      debounce(
        async (
          input: string,
          callback: (options: { label: string; value: Lesson }[]) => void,
        ) => {
          const lessons = await trpcClient.lesson.find.query({
            filter: input,
            onlyPages,
          });
          const options = lessons.map((lesson) => ({
            label: lesson.title,
            value: lesson,
          }));
          callback(options);
        },
        800,
      ),
    [onlyPages],
  );

  const loadOptions = useCallback(
    (
      inputValue: string,
      callback: (options: { label: string; value: Lesson }[]) => void,
    ) => {
      fetchOptions(inputValue, callback);
    },
    [fetchOptions],
  );

  const handleChange = useCallback(
    (option: { label: string; value: Lesson } | null) => {
      const newSelectedLesson = option ? option.value : null;
      setSelectedLesson(newSelectedLesson);
      onSelectionChange(newSelectedLesson);
    },
    [onSelectionChange],
  );

  const isOptionSelected = useCallback(
    (
      option: { label: string; value: Lesson },
      selectValue: readonly { label: string; value: Lesson }[],
    ) => {
      return option.value.id === selectValue[0]?.value.id;
    },
    [],
  );

  return (
    <div>
      <AsyncSelect
        key={selectedLessonId}
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        onChange={handleChange}
        value={
          selectedLesson
            ? { label: selectedLesson.title, value: selectedLesson }
            : null
        }
        placeholder="Select a Lesson"
        isClearable
        isOptionSelected={isOptionSelected}
      />
    </div>
  );
};
