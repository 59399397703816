import type {
  UpdateActiveSessionActivityInput,
  AppendActiveSessionActivityInput,
} from "@arena-active/trpc-client";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useCallback, useEffect, useState } from "react";
import { EditLessonActivity } from "./EditLessonActivity";
import { ActivityType } from "@arena-active/trpc-client";
import {
  EditExerciseActivity,
  ExerciseAndObjective,
} from "./EditExerciseActivity";
import { TabPanel, TabView } from "primereact/tabview";
import { EditActivityInterstitial } from "./interstitial/EditActivityInterstitial";
import { ActivityInterstitial } from "@arena-active/schema";
import { EditUploadActivity } from "./EditUploadActivity";
// import { EditVideoActivity } from "./EditVideoActivity";
// import { EditExerciseActivity } from "./EditExerciseActivity";
// import { EditUploadActivity } from "./EditUploadActivity";
// import { EditChatActivity } from "./EditChatActivity";

interface SessionActivityEditorProps {
  activeSessionId: number;
  initialActivity?:
    | AppendActiveSessionActivityInput
    | UpdateActiveSessionActivityInput;
  updateActivity: (
    activity:
      | UpdateActiveSessionActivityInput
      | AppendActiveSessionActivityInput,
  ) => void;
  updateExerciseAndObjective: (
    exerciseAndObjective: ExerciseAndObjective,
  ) => void;
}

export const SessionActivityEditor: React.FC<SessionActivityEditorProps> = ({
  activeSessionId,
  initialActivity,
  updateActivity,
  updateExerciseAndObjective,
}) => {
  const activityTypes: ActivityType[] = Object.values(ActivityType);
  const [selectedActivityType, setSelectedActivityType] =
    useState<ActivityType | null>(null);

  const [activity, setActivity] = useState<
    | UpdateActiveSessionActivityInput
    | AppendActiveSessionActivityInput
    | undefined
  >(initialActivity);

  const [preInterstitial, setPreInterstitial] = useState<ActivityInterstitial>({
    model: { title: "", content: "" },
    isStart: true,
    hideWhenSeen: false,
  });
  const [postInterstitial, setPostInterstitial] =
    useState<ActivityInterstitial>({
      model: { title: "", content: "" },
      isStart: false,
      hideWhenSeen: false,
    });

  const update = useCallback(
    (
      activity:
        | UpdateActiveSessionActivityInput
        | AppendActiveSessionActivityInput,
    ) => {
      updateActivity(activity);
    },
    [updateActivity],
  );

  const rejectEmptyInterstitials = (interstitials: ActivityInterstitial[]) =>
    interstitials.filter(
      (inter) => inter.model.content.length > 0 || inter.model.title.length > 0,
    );

  const onUpdatePre = useCallback(
    (u: ActivityInterstitial) => {
      setPreInterstitial(u);
      if (activity) {
        update({
          ...activity,
          interstitials: rejectEmptyInterstitials([u, postInterstitial]),
        });
      }
    },
    [activity, postInterstitial, update],
  );

  const onUpdatePost = useCallback(
    (u: ActivityInterstitial) => {
      setPostInterstitial(u);
      if (activity) {
        update({
          ...activity,
          interstitials: rejectEmptyInterstitials([preInterstitial, u]),
        });
      }
    },
    [activity, preInterstitial, update],
  );

  useEffect(() => {
    const pre = initialActivity?.interstitials?.find(
      (interstitial) => interstitial.isStart === true,
    );
    if (pre) {
      setPreInterstitial(pre as ActivityInterstitial);
    }

    const post = initialActivity?.interstitials?.find(
      (interstitial) => interstitial.isStart === false,
    );
    if (post) {
      setPostInterstitial(post as ActivityInterstitial);
    }
  }, [initialActivity]);

  const dropdownOptions = activityTypes.map((type: string) => ({
    label: type,
    value: type,
  }));

  const handleChange = (e: DropdownChangeEvent) => {
    const selection = e.value as ActivityType;

    setActivity((prev) => ({
      ...prev,
      activeSessionId,
      activityType: selection,
    }));
    setSelectedActivityType(selection);
  };

  const renderActivityEditor = (
    activity:
      | AppendActiveSessionActivityInput
      | UpdateActiveSessionActivityInput,
  ) => {
    switch (activity?.activityType) {
      case "LESSON_PAGE":
      case "VIDEO":
        return <EditLessonActivity activity={activity} save={update} />;
      case "EXERCISE":
        return (
          <EditExerciseActivity
            activity={activity}
            save={update}
            onUpdateExerciseAndObjective={updateExerciseAndObjective}
          />
        );
      // case "VIDEO":
      //   return <EditVideoActivity activity={activity} />;
      case "UPLOAD":
        return <EditUploadActivity activity={activity} save={update} />;
      // case "CHAT":
      //   return <EditChatActivity activity={activity} />;
      default:
        return <div>Unknown activity type</div>;
    }
  };

  if (!activity) {
    return <div>error: no activity</div>;
  }
  return (
    <div>
      {activity?.activityType ? (
        <TabView>
          <TabPanel header="Edit Activity">
            {renderActivityEditor(activity)}
          </TabPanel>
          <TabPanel header="Interstitials">
            <TabView>
              <TabPanel header="Pre-Activity">
                <EditActivityInterstitial
                  initialData={preInterstitial}
                  onUpdate={onUpdatePre}
                />
              </TabPanel>
              <TabPanel header="Post-Activity">
                <EditActivityInterstitial
                  initialData={postInterstitial}
                  onUpdate={onUpdatePost}
                />
              </TabPanel>
            </TabView>
          </TabPanel>
        </TabView>
      ) : (
        <Dropdown
          options={dropdownOptions}
          onChange={handleChange}
          value={selectedActivityType}
          optionLabel="label"
          placeholder="Select an activity type"
        />
      )}
    </div>
  );
};
