import { useState, RefObject, useCallback } from "react";
import useKeypress from "react-use-keypress";
import { SettingsType } from "./MediaPlayerElements";
import { Option } from "./SettingsMenu";

interface UseMediaPlayerProps {
  mediaRef: RefObject<HTMLMediaElement>;
}

const DEFAULT_VOLUME: number = 75;

function useMediaPlayer({ mediaRef }: UseMediaPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volumeLevel, setVolumeLevel] = useState(DEFAULT_VOLUME);
  const [volumeBarOpen, setVolumeBarOpen] = useState<boolean>(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState<boolean>(false);
  const [settingsState, setSettingsState] = useState<SettingsType>({
    subtitle: { id: "off", label: "off" },
    playbackSpeed: { id: "normal", label: "normal" },
    quality: { id: "auto", label: "auto" },
  });

  const updateSettingsState = useCallback(
    (menuName: string, option: Option) => {
      setSettingsState((prevState: SettingsType) => ({
        ...prevState,
        [menuName as string]: option,
      }));

      if (menuName === "playbackSpeed") {
        const value = option?.id;
        const strToNumber = (str: string) => {
          if (str === "normal") {
            return 1;
          }
          return Number(str);
        };
        if (mediaRef.current) {
          mediaRef.current.playbackRate = strToNumber(value);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useKeypress(["p", "m"], (event: KeyboardEvent) => {
    if (event.key === "p") {
      isPlaying ? pause() : play();
    }
    if (event.key === "m") {
      volumeLevel > 0 ? setVolume(0) : setVolume(DEFAULT_VOLUME);
    }
  });

  const play = (): void => {
    if (!mediaRef?.current?.src) return;
    if (mediaRef.current.duration !== Infinity)
      setDuration(mediaRef.current.duration);

    mediaRef.current?.play();
    setIsPlaying(true);

    const handleTimeUpdates = (): void => {
      if (mediaRef?.current) {
        if (mediaRef?.current?.currentTime >= duration) {
          pause();
          seekTo(0);
          return;
        }
        setCurrentTime(mediaRef.current.currentTime);
      }

      requestAnimationFrame(handleTimeUpdates);
    };

    requestAnimationFrame(handleTimeUpdates);
  };

  const pause = useCallback((): void => {
    mediaRef?.current?.pause();
    setIsPlaying(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const seekTo = useCallback((time: number): void => {
    if (mediaRef.current) {
      mediaRef.current.currentTime = time;
      setCurrentTime(time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setVolume = useCallback((volume: number): void => {
    if (mediaRef.current) {
      mediaRef.current.volume = volume / 100;
      setVolumeLevel(volume);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openVolumeBar = (): void => setVolumeBarOpen(true);
  const closeVolumeBar = (): void => setVolumeBarOpen(false);
  const openSettingsMenu = (): void => setSettingsMenuOpen(true);
  const closeSettingsMenu = (): void => setSettingsMenuOpen(false);

  return {
    isPlaying,
    play,
    pause,
    currentTime,
    setCurrentTime,
    duration,
    setDuration,
    seekTo,
    volumeLevel,
    setVolume,
    volumeBarOpen,
    openVolumeBar,
    closeVolumeBar,
    settingsMenuOpen,
    openSettingsMenu,
    closeSettingsMenu,
    settingsState,
    updateSettingsState,
  };
}

export default useMediaPlayer;
