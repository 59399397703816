import { useCallback, useEffect, useRef, useState } from "react";
import { SettingsMenu } from "./SettingsMenu";
import { Tooltip } from "../menu/Tooltip";
import styles from "./MediaPlayer.module.css";
import useMediaPlayer from "./useMediaPlayer";
import {
  menuItems,
  TrackBarControl,
  VolumeControl,
  SettingsControl,
  PlayControl,
  TimerControl,
} from "./MediaPlayerElements";
import { classNames } from "primereact/utils";
import {
  FullScreenIcon,
  CaptionsIcon,
  ExitFullScreenIcon,
  CaptionsOffIcon,
} from "../common/Icons";
import { Button } from "primereact/button";
import useKeypress from "react-use-keypress";
import { useMediaQuery } from "react-responsive";

interface VideoPlayerProps {
  source?: string;
  type?: string;
}

export function VideoPlayer({ source, type }: VideoPlayerProps): JSX.Element {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoWrapperRef = useRef<HTMLDivElement>(null);

  const [controlsVisible, setControlsVisible] = useState<boolean>(true);
  const hideControlsTimeout = useRef<number | null>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isCaptionsOn, setIsCaptionsOn] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 960 });

  const {
    isPlaying,
    play,
    pause,
    currentTime,
    duration,
    setDuration,
    seekTo,
    volumeLevel,
    setVolume,
    volumeBarOpen,
    openVolumeBar,
    closeVolumeBar,
    settingsMenuOpen,
    openSettingsMenu,
    closeSettingsMenu,
    settingsState,
    updateSettingsState,
  } = useMediaPlayer({
    mediaRef: videoRef,
  });

  const resetHideControlsTimer = useCallback(() => {
    if (hideControlsTimeout.current) {
      clearTimeout(hideControlsTimeout.current);
    }

    // If the volume bar or settings menu is open, do not hide controls
    if (volumeBarOpen || settingsMenuOpen) {
      setControlsVisible(true);
      return;
    }

    if (isPlaying) {
      hideControlsTimeout.current = window.setTimeout(() => {
        setControlsVisible(false);
      }, 2000);
    }
  }, [isPlaying, volumeBarOpen, settingsMenuOpen, setControlsVisible]);

  const showControls = useCallback(() => {
    setControlsVisible(true);
    resetHideControlsTimer();
  }, [setControlsVisible, resetHideControlsTimer]);

  const handleUserActivity = () => showControls();

  const toggleFullScreen = () => {
    if (
      videoWrapperRef?.current &&
      videoWrapperRef.current?.requestFullscreen &&
      !document.fullscreenElement
    ) {
      videoWrapperRef.current.requestFullscreen();
      return;
    }
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  useKeypress(["f"], (event: KeyboardEvent) => {
    if (event.key === "f") {
      toggleFullScreen();
    }
  });

  useEffect(
    function handleIsPlayingChange() {
      if (isPlaying) {
        resetHideControlsTimer();
        return;
      }
      if (hideControlsTimeout.current) {
        clearTimeout(hideControlsTimeout.current);
      }
      setControlsVisible(true);
    },
    [isPlaying, setControlsVisible, resetHideControlsTimer],
  );

  useEffect(
    function handleInteractiveElementsChange() {
      // Reset the timer whenever volumeBarOpen or settingsMenuOpen changes
      resetHideControlsTimer();
    },
    [volumeBarOpen, settingsMenuOpen],
  );

  useEffect(
    function handleSourceChange() {
      if (source && videoRef && videoRef.current) {
        const video = videoRef.current;

        video.ondurationchange = () => {
          if (video.duration !== Infinity) {
            setDuration(video.duration);
          }
        };

        video.onloadedmetadata = () => {};

        video.load();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [source],
  );

  useEffect(
    function hideControlsAfterHitPlay() {
      if (isPlaying) {
        setTimeout(() => setControlsVisible(false), 500);
      }
    },
    [isPlaying],
  );

  useEffect(function mounted() {
    document.addEventListener("fullscreenchange", () => {
      if (document.fullscreenElement) {
        setIsFullScreen(true);
        return;
      }
      setIsFullScreen(false);
    });
    return () => {
      if (hideControlsTimeout.current) {
        clearTimeout(hideControlsTimeout.current);
      }
    };
  }, []);

  const captionsAndSettingsControl = (
    <>
      <Button
        className={`${styles.controlButton} tooltip`}
        icon={isCaptionsOn ? <CaptionsOffIcon /> : <CaptionsIcon />}
        onClick={(e) => {
          e.stopPropagation();
          setIsCaptionsOn(!isCaptionsOn);
        }}
        data-pr-tooltip="Subtitles/Closed captions (C)"
        data-pr-position="top"
        data-pr-at="center top-13"
        data-pr-disabled={settingsMenuOpen}
      />

      <SettingsControl
        settingsMenuOpen={settingsMenuOpen}
        openSettingsMenu={openSettingsMenu}
      />
    </>
  );

  return (
    <div
      ref={videoWrapperRef}
      className={classNames(styles.topContainer, styles.video)}
      onMouseMove={handleUserActivity}
      onClick={handleUserActivity}
      onTouchStart={handleUserActivity}
    >
      {isMobile && (
        <div
          className={classNames(
            styles.playControlMobile,
            controlsVisible ? styles.controlsVisible : styles.controlsHidden,
          )}
          onClick={isPlaying ? pause : play}
        >
          <PlayControl isPlaying={isPlaying} onPause={pause} onPlay={play} />
        </div>
      )}
      {isMobile && (
        <div
          className={classNames(
            styles.settingsControlMobile,
            controlsVisible ? styles.controlsVisible : styles.controlsHidden,
          )}
        >
          {captionsAndSettingsControl}
        </div>
      )}
      <video
        className={styles.videoStyle}
        ref={videoRef}
        preload="metadata"
        src={source}
      >
        <source src={source} type={type} />
      </video>
      {settingsMenuOpen && (
        <SettingsMenu
          settingsState={settingsState}
          menuItems={menuItems}
          updateSettingsState={updateSettingsState}
          closeSettingsMenu={closeSettingsMenu}
          posRight={isMobile ? 9 : 17}
          posBottom={isMobile ? "unset" : 64}
          posTop={isMobile ? 64 : "unset"}
        />
      )}
      {duration > 0 && (
        <div
          className={classNames(
            styles.container,
            styles.videoPlaybar,
            controlsVisible ? styles.controlsVisible : styles.controlsHidden,
          )}
        >
          <Tooltip hideOnTargetClick target=".tooltip" />
          <div className={styles.playbackContainer}>
            {!isMobile && (
              <div className={styles.playbackButtonContainer}>
                <PlayControl
                  isPlaying={isPlaying}
                  onPause={pause}
                  onPlay={play}
                />
              </div>
            )}

            <TimerControl currentTime={currentTime} duration={duration} />
          </div>
          <TrackBarControl
            duration={duration}
            currentTime={currentTime}
            seekTo={seekTo}
          />
          <div className={styles.volumeAndSettingsControl}>
            {!isMobile && (
              <VolumeControl
                setVolume={setVolume}
                volumeLevel={volumeLevel}
                volumeBarOpen={volumeBarOpen}
                openVolumeBar={openVolumeBar}
                closeVolumeBar={closeVolumeBar}
                settingsMenuOpen={settingsMenuOpen}
              />
            )}

            {!isMobile && captionsAndSettingsControl}
          </div>
          <div className={styles.fullScreenButton}>
            <Button
              className={`${styles.controlButton} tooltip`}
              icon={isFullScreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
              onClick={(e) => {
                e.stopPropagation();
                toggleFullScreen();
              }}
              data-pr-tooltip="Full Screen (F)"
              data-pr-position="top"
              data-pr-at="center top-13"
              data-pr-disabled={settingsMenuOpen}
            />
          </div>
        </div>
      )}
    </div>
  );
}
