import { Dictionary } from "lodash";
export { debounce } from "lodash";
import { fixWebmDuration } from "@fix-webm-duration/fix";

export { testAudioData } from "./testAudioData";

export const createGUID = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export function required(name: string, value: string | undefined) {
  if (value === undefined) {
    throw new Error(`Missing required environment variable ${name}`);
  }
  return value;
}

export const formatTime = (seconds: number): string =>
  new Date(seconds * 1000).toISOString().slice(14, 19);

export const convertDataURIToBinary = (dataURI: string) => {
  const BASE64_MARKER = ";base64,";
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;

  if (base64Index === -1) {
    console.error("Base64 marker not found in dataURI.");
    return;
  }

  const base64 = dataURI.substring(base64Index);

  const raw = window.atob(base64);

  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }

  return array;
};

export const convertToOptions = (
  devices: Dictionary<MediaDeviceInfo[]>,
  input: string,
) =>
  devices[input].map((device: MediaDeviceInfo) => ({
    id: device?.deviceId,
    label: device.label,
  }));

export const getSystemTheme = () => {
  return window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";
};

export const combineVideoSegmentsFixedDuration = async (
  videoSegments: BlobPart[],
  videoMimeType: string,
  videoStartTime: number,
) => {
  const combinedVideoBlob = new Blob(videoSegments, {
    type: videoMimeType,
  });

  if (videoMimeType.includes("webm")) {
    const fixedDurationVideoBlob = await fixWebmDuration(
      combinedVideoBlob,
      performance.now() - videoStartTime,
    );
    return fixedDurationVideoBlob;
  }

  return combinedVideoBlob;
};
