import ReactDOM from "react-dom";
import styles from "./Modal.module.css";
import { ThemeContainer } from "../../theme";
import { Button } from "primereact/button";
import { CloseIcon } from "./Icons";
import useDisableBodyScroll from "./hooks/useDisableBodyScroll";

interface ModalProps {
  show: boolean;
  title: string;
  onCloseButtonClick: () => void;
  children: React.ReactNode;
}

export function Modal({
  show,
  title,
  onCloseButtonClick,
  children,
}: ModalProps) {
  useDisableBodyScroll(show);

  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <ThemeContainer>
      <div className={styles.modalWrapper} onClick={onCloseButtonClick}>
        <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            <Button
              className={styles.closeButton}
              icon={<CloseIcon fillColor="var(--text-body)" />}
              onClick={onCloseButtonClick}
            />
          </div>
          <div className={styles.body}>{children}</div>
        </div>
      </div>
    </ThemeContainer>,
    document.body,
  );
}
